<template>
  <div>
    <strong class="text-black text-2xl font-semibold">Retur Insight</strong>
    <BCard class="mt-2">
      <BRow class="justify-between">
        <strong class="text-black text-lg font-semibold ml-1">Grafik Retur Ekspedisi</strong>
        <BButton
          size="sm"
          variant="outline-primary"
          style="padding: 0.4rem 2rem; border: 1px solid #ECE9F1 !important; color: black;"
          class="cursor-pointer"
        >
          <BRow>
            <YearPicker
              v-model="filterChart"
              :format="START_YEAR_NOW"
              minimum-view="year"
              name="datepicker"
              wrapper-class="border-solid border-slate-200 rounded cursor-pointer"
              calendar-class="w-full ml-[-22em]"
            />
            <b-img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              class="w-3"
            />
          </BRow>
        </BButton>
      </BRow>
      <BOverlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <Apexcharts
          ref="chart"
          height="300"
          type="bar"
          :options="chartOptions"
          :series="seriesChart"
        />

      </BOverlay>
    </BCard>
    <BCard>
      <BRow class="justify-between">
        <strong class="text-black text-lg font-semibold ml-1">Rincian Retur</strong>
        <MonthlyPicker
          v-model="filterList"
          date-format="MMM YYYY"
          place-holder="Pilih Bulan"
          class="mr-1"
          :month-labels="monthlabel"
          :max="maxDatePicker"
        />
      </BRow>
      <BOverlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.5"
        rounded="sm"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
        >
          <template #cell(jne)="data">
            <div class="flex items-center">
              <span>{{ data.item.JNE }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('jne')"
              >
            </div>
          </template>
          <template #cell(sap)="data">
            <div class="flex items-center">
              <span>{{ data.item.SAP }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('sap')"
              >
            </div>
          </template>
          <template #cell(sicepat)="data">
            <div class="flex items-center">
              <span>{{ data.item.SICEPAT }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('sicepat')"
              >
            </div>
          </template>
          <template #cell(idexpress)="data">
            <div class="flex items-center">
              <span>{{ data.item.IDEXPRESS }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('idexpress')"
              >
            </div>
          </template>
          <template #cell(ninja)="data">
            <div class="flex items-center">
              <span>{{ data.item.NINJA }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('ninja')"
              >
            </div>
          </template>
          <template #cell(j&t)="data">
            <div class="flex items-center">
              <span>{{ data.item['J&T'] }}</span>
              <img
                v-if="data.item.type_name === 'Tidak Ada Data Ticket Order Retur'"
                v-b-tooltip.hover.top="'Download File Excel'"
                src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                alt="Komerce"
                class="ml-[5px] cursor-pointer"
                @click="downloadDoc('j&t')"
              >
            </div>
          </template>
          <template v-slot:custom-foot>
            <BTr class="text-black font-semibold">
              <BTd class="footerTotal">
                Total
              </BTd>
              <BTd class="footerTotal">
                {{ total.JNE }}
              </BTd>
              <BTd class="footerTotal">
                {{ total.SICEPAT }}
              </BTd>
              <BTd class="footerTotal">
                {{ total.IDEXPRESS }}
              </BTd>
              <BTd class="footerTotal">
                {{ total.SAP }}
              </BTd>
              <BTd class="footerTotal">
                {{ total.NINJA }}
              </BTd>
              <BTd class="footerTotal">
                {{ total['J&T'] }}
              </BTd>
            </BTr>
          </template>
        </BTable>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import YearPicker from 'vuejs-datepicker'
import {
  BRow, BCard, BTable, BTr, BTd,
} from 'bootstrap-vue'
import MonthlyPicker from 'vue-monthly-picker'
import {
  LABELMONTH, MONTH, START_YEAR_NOW, YEAR,
} from '@/libs/filterDate'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import Apexcharts from 'vue-apexcharts'
import { chartOptions, tableReturInsight } from './config'

export default {
  components: {
    BRow,
    BCard,
    MonthlyPicker,
    BTable,
    Apexcharts,
    YearPicker,
    BTr,
    BTd,
  },
  data() {
    return {
      monthlabel: LABELMONTH,
      fields: tableReturInsight,
      items: [],
      total: {},
      isLoading: false,
      seriesChart: [],
      chartOptions,
      filterChart: moment()
        .format('YYYY-MM'),
      START_YEAR_NOW,
      filterList: this.$moment(),

    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment()
        .endOf('month')
    },
  },
  watch: {
    filterChart: {
      handler() {
        this.getDataChart()
      },
    },
    filterList: {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
    this.getDataChart()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const url = '/v2/admin/dashboard/list/analytic/returInsight'
      const params = {
        year: YEAR(this.filterList),
        month: MONTH(this.filterList),
      }
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data, total } = res.data.data
          this.items = data
          this.isLoading = false
          this.total = total
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoading = false
        })
    },
    async getDataChart() {
      this.isLoading = true
      const params = {
        filter: this.START_YEAR_NOW(this.filterChart),
      }
      const url = '/v2/admin/dashboard/analytic/returInsight'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          const expeditions = Object.keys(data[0]).filter(key => key !== 'month_name' && key !== 'month_number' && key !== 'year')
          this.seriesChart = expeditions.map(name => ({
            name,
            data: data.map(item => item[name]),
          }))
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(item => item.month_name),
            },
          }
          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoading = false
        })
    },
    async downloadDoc(type) {
      this.isLoading = true
      const params = {
        year: YEAR(this.filterList),
        month: MONTH(this.filterList),
      }
      const url = `/v1/admin/dashboard/analytic/returInsight/download-retur-nonticket/${type}`
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res
          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
          a.download = `Uncreate ticket ${type}/${params.month}.xls`
          a.click()
          this.isLoading = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Coba Lagi',
              icon: 'AlertCircleIcon',
              text: 'Terjadi kesalahan saat download file,',
              variant: 'danger',
            },
          }, 1000)
          this.isLoading = false
        })
    },
  },
}
</script>
<style scoped>
.footerTotal {
  background-color: #C2C2C2;
}
</style>
